<template>
  <div>
    <h4 class="flix-html-h4">{{ $t('message.desiredAppointment') }} ({{ $t('message.' + data.status ) }})</h4>
    <div class="flix-form-group">
      <div><b>{{ $t('message.appointmentFormat') }}:</b> {{ getGroupTxt(data) }}</div>
      <span v-html="data.booking_date" />
    </div>
    <div class="flix-form-group">
      <editCalendar :data="data" />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    editCalendar () { return import('@/components/bookingInfoPage/edit/edit.vue') }
  },
  props: {
    data: Object
  },
  data () {
    return {

    }
  },
  methods: {
    getGroupTxt (entry) {
      return this.$getGroupTxt(entry, this)
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
